/**
 * @description Styles for the scrollbar when this is neccesary on the policy row on the superior bar
 */
 .planner_row_block::-webkit-scrollbar {
    width:10px;
    background-color: #f1f1f1;
    border-radius: 10px;
}

.planner_row_block::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
    width: 1px;
}

.planner_row_block::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

/**
 * @description Background color of the modal
 */
 .modal-block-font {
    background-color: #616161 !important;
}

/**
 * @description Style for the scrollbar of the modal
*/
.height-modal-config::-webkit-scrollbar {
    width:10px;
    background-color: #f1f1f1;
    border-radius: 10px;
}

.height-modal-config::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
    width: 1px;
}

.height-modal-config::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}