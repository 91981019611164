/**
 * @description Define the style of the scroll on the imputations row
 */
.container-imputations-row::-webkit-scrollbar {
    width:10px;
    background-color: #f1f1f1;
    border-radius: 10px;
}

.container-imputations-row::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
    width: 1px;
}

.container-imputations-row::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

/**
 * @description Background color of the modal
 */
.modal-wallpaper-nego {
    background-color: #616161 !important;
}

/**
 * @description Style for the scroll in the modal body
 */
.height-modal-config-nego::-webkit-scrollbar {
    width:10px;
    background-color: #f1f1f1;
    border-radius: 10px;
}

.height-modal-config-nego::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
    width: 1px;
}

.height-modal-config-nego::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

/**
 * @description Locate the clear indicator in the top of the multiselect
 */
.select__clear-indicator {
    position: absolute;
    top: 0;
    right: 30px;
}