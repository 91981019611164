.boxes-row > div {
    flex: 1 0 20%; /* Cambia el 20% por el tamaño que desees */
}

.boxes-row::-webkit-scrollbar {
    width:5px;
    background-color: #f1f1f1;
    border-radius: 10px;
}

.boxes-row::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
    width: 1px;
}

.boxes-row::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}