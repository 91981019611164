/**
 * @description Modified imported classes of the modal in policy management
*/
.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    color: var(--bs-modal-color);
    pointer-events: auto;
    background-color: var(--bs-modal-bg);
    background-clip: padding-box;
    border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
    border-radius: var(--bs-modal-border-radius);
    outline: 0;
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: var(--bs-modal-padding);
    height: 80%;
}

/**
 * @description Condition to hide the arrows in the input number
*/
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
}

/**
 * @description Styles for the scrollbar when this is neccesary on the policy row on the superior bar
*/
.bar-element-policy::-webkit-scrollbar {
    width:10px;
    background-color: #f1f1f1;
    border-radius: 10px;
}

.bar-element-policy::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
    width: 1px;
}

.bar-element-policy::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

/**
 * @description Style for the scrollbar of the modal
*/
.height-modal-config-policy::-webkit-scrollbar {
    width:10px;
    background-color: #f1f1f1;
    border-radius: 10px;
}

.height-modal-config-policy::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
    width: 1px;
}

.height-modal-config-policy::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

/**
 * @description Custom style for the MUI dialog component
*/
.css-6hp17o-MuiList-root-MuiMenu-list {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    outline: 0;
}