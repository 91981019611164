/**
 * @description This class establish the image font for the view.
 */
.image-wallpaper {
    background-image: url('../images/newLoginWallpaper.png');
    background-size: cover;
    background-repeat: no-repeat;
}

.dialog-important {
    width: calc(12% * var(--quantity-countries)) !important;
    max-width: 100% !important;
}