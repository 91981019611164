.bar-element-user::-webkit-scrollbar {
    width:10px;
    background-color: #f1f1f1;
    border-radius: 10px;
}

.bar-element-user::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
    width: 1px;
}

.bar-element-user::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}


#input-first.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input,
#input-second.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {
    font: inherit;
    letter-spacing: inherit;
    color: currentColor;
    border: 0;
    box-sizing: content-box;
    background: none;
    height: 1.4375em;
    margin: 0;
    -webkit-tap-highlight-color: transparent;
    display: block;
    min-width: 0;
    width: 100%;
    -webkit-animation-name: mui-auto-fill-cancel;
    animation-name: mui-auto-fill-cancel;
    -webkit-animation-duration: 10ms;
    animation-duration: 10ms;
    padding: 8.5px 14px;
    text-transform: capitalize;
}

