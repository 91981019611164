@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/**
 * @description Define the background color for the entire view
 */
.wallpaper {
  background: linear-gradient(to bottom, #ffffff, #BFBFBF);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1000;
}

.load-logo {
  width: 10%;
}

.load-logo-modal {
  width: 20%;
}

.wallpaper-modal {
  background: linear-gradient(to right, #616161 50%, #454545 50%);
}

.small-square {
  width: 13px;
  height: 13px;
  background-color:#D9D9D9;
  border-radius: 20%;
}

@media (min-width: 2560px) {
  .small-square {
    width: 15px;
    height: 15px;
  }
}